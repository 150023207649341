@import "froala-editor/css/froala_style.min.css";

.dsa-modal {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .p-0 {
    padding: 0 !important;
  }

  .announcements-modal-content {
    border: none !important;
    border-radius: 0 !important;

    .announcements-modal-header {
      border-bottom: 0 !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      padding: 0 !important;
      z-index: 1;
      min-height: 0 !important;
      
      img {
        max-width: 100%;
        max-height: 200px;

        @media (min-width: 820px) {
          min-height: 200px;
        }
      }

      .btn-close {
        margin: 0 !important;
        position: absolute;
        top: 5px;
        right: 5px;
        background-color: #ffffff;
        opacity: 0.7 !important;
        height: 0.5rem !important;
        width: 0.5rem !important;
        padding: 7px;
        cursor: pointer;
      }
    }

    .announcements-modal-body {
      .announcements-modal-heading {
        display: inline-block;
        width: 100%;
        font-size: 1.7rem;
        text-align: center;
      }

      .announcements-modal-subheading {
        display: inline-block;
        width: 100%;
        font-size: 1.3rem;
        text-align: center;
        margin-bottom: 1rem;
      }

      .announcements-modal-content-image {
        display: block;
        max-width: 100%;
      }
    }
  }

  @media (min-width: 820px) {
    .announcements-modal-dialog {
      max-width: 800px !important;
    }
  }
}
